import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import { Box, CircularProgress, Snackbar, Modal, Button, Typography } from "@mui/material";

import moment from "moment";

import DeleteModal from "../../components/Modals/DeleteModal/DeleteModal";
import ListTable from "../../components/ListTable/ListTabel";

import { deleteListItem, getListItem } from "../../services/DataService";

import { Alert } from "../../utils/alert";

import SaveListItemModal from "./SaveListItemModal";
import { useListItemsAPI } from "../../hooks/useListItemsAPI";
import ImportFileModal from "./ImportFileModal";

const ListItemsPage = () => {
  const { id } = useParams();

  const {
    listItemsData: { data, isLoading, isError },
  } = useListItemsAPI({ listId: id });

  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [defaultValues, setDefaultValues] = useState(null);
	const [showImportFileModal, setShowImportFileModal] = useState(false);
  const [alert, setAlert] = useState({
    isShow: false,
    type: "success",
    message: "List item created successfully",
  });

  const queryClient = useQueryClient();

  const Delete = useMutation(deleteListItem, {
    onSuccess: (data) => {
      handleOpenAlert("success", "List item deleted successfully");
    },
    onError: () => {
      handleOpenAlert("error", "Oops, something went wrong");
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });

  const handleOpenSaveModal = () => setShowSaveModal(true);
  const handleCloseSaveModal = () => setShowSaveModal(false);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

	const handleOpenImportFileModal = () => setShowImportFileModal(true);
  const handleCloseImportFileModal = () => setShowImportFileModal(false);

  const redirectAddNew = () => {
    setDefaultValues(null);
    handleOpenSaveModal();
  };

  const handleOpenDeleteModal = (listItem) => {
    setDeleteId({ id: listItem.id });
    setShowDeleteModal(true);
  };

  const handleCloseAlert = () => {
    setAlert(false);
  };

  const handleOpenAlert = (type, message) => {
    setAlert({ isShow: true, type: type, message });
  };

  const redirectIdListItem = async (listItem) => {
    const data = await getListItem(id, listItem.id);
    setDefaultValues(data);
    handleOpenSaveModal();
  };

  const handleDeleteListItem = (listItem) => {
    handleCloseDeleteModal();
    Delete.mutate({ listId: id, itemId: deleteId.id });
  };

  const headers = [
    {
      lable: "Email",
      align: "left",
    },
    {
      lable: "Phone",
      align: "left",
    },
    {
      lable: "Created at",
      align: "right",
    },
    {
      lable: "",
      align: "left",
    },
  ];
  const columns = [
    {
      accessor: (element) => element["email"] ?? "-",
      Cell: (event, list) => {},
      align: "left",
    },
    {
      accessor: (element) => element["phone"] ?? "-",
      Cell: (event, list) => {},
      align: "left",
    },
    {
      accessor: (element) => moment(element.createdAt).format("MM/DD/YY HH:MM"),
      Cell: (event, action) => {},
      align: "left",
    },
  ];

  if (isLoading) {
    return (
      <Box className="wrapper-loading">
        <CircularProgress />
      </Box>
    );
  }
  if (isError) {
    return <h1>Something wrong</h1>;
  }
  return (
    <>
      <Box>
        <Box sx={{ m: "20px", textAlign: "end", gap: "12px", display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={handleOpenImportFileModal} variant="import" size="large">
            Import CSV file
          </Button>
          <Button onClick={redirectAddNew} variant="addNew" size="large">
            New List Item
          </Button>
        </Box>
        <Box sx={{ mx: "20px", textAlign: "start" }}>
          <Typography variant={"h3"}>{data.name}</Typography>
        </Box>
        <Box className="wrapper-source-list" sx={{ padding: "24px" }}>
          <ListTable headers={headers} data={data.result} handleDelete={handleOpenDeleteModal} redirectId={redirectIdListItem} columns={columns} />
        </Box>
        <Snackbar open={alert.isShow} autoHideDuration={3500} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity={alert.type} sx={{ width: "100%" }}>
            {alert.message}
          </Alert>
        </Snackbar>
      </Box>
			<Modal
        open={showImportFileModal}
        onClose={handleCloseImportFileModal}
        sx={{ backdropFilter: "blur(14px)" }}
      >
        <ImportFileModal listId={id} handleCloseSaveModal={handleCloseImportFileModal} />
      </Modal>
      <Modal open={showSaveModal} onClose={handleCloseSaveModal} sx={{ backdropFilter: "blur(14px)" }}>
        <SaveListItemModal listId={id} handleCloseSaveModal={handleCloseSaveModal} defaultValues={defaultValues} />
      </Modal>
      <Modal open={showDeleteModal} onClose={handleCloseDeleteModal} sx={{ backdropFilter: "blur(14px)" }}>
        <DeleteModal handleCloseDeleteModal={handleCloseDeleteModal} handleDelete={handleDeleteListItem} text={"List Item"} name={deleteId?.email} />
      </Modal>
    </>
  );
};

export default ListItemsPage;
