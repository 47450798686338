import React, { useState } from "react";
import { Box, Typography, Snackbar, IconButton } from "@mui/material";
import CancelButton from "../../components/SaveModalButtons/CancelButton";
import SaveButton from "../../components/SaveModalButtons/SaveButton";
import { Dropzone } from "../../components/Dropzone/Dropzone";
import DeleteIcon from "../../assets/DeleteSource.svg";
import { Alert } from "../../utils/alert";
import style from "./styles.module.css";
import ".././style.css";
import { useListItemsAPI } from "../../hooks/useListItemsAPI";
import useEscapeClose from "../../hooks/CloseModal/CloseModal";

const ImportFileModal = ({ listId, handleCloseSaveModal }) => {
  const { postListItemImport } = useListItemsAPI({ listId, handleCloseSaveModal });

  const [file, setFile] = useState("");
  const [alert, setAlert] = useState({
    isShow: false,
    type: "success",
    message: "File imported successfully",
  });

  useEscapeClose(handleCloseSaveModal);

  const handleCloseAlert = () => {
    setAlert(false);
  };

  const handleSetFile = (files) => {
    setFile(files[0]);
  };
  const handleDeleteFile = () => {
    setFile("");
  };

  const validation = () => {
    if (file) {
      return false;
    } else {
      return true;
    }
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  // Post CSV list as base64
  const handlePostListFile = async () => {
    const base64File = await convertFileToBase64(file);

    postListItemImport.mutate({
      listId,
      data: { content: base64File },
    });
  };

  return (
    <>
      <Box className={style.modal}>
        <Typography variant={"h1"} className={style.title}>
          Import File
        </Typography>

        <Typography sx={{ fontWeight: "medium", fontSize: "12px", color: "gray", marginBottom: "6px" }}>
          CSV import requires 1 or both of following fields:
          <br /> 'phone', 'email'{" "}
        </Typography>

        {/* Example format:  */}
        {/* <table>
          <tr>
            <td style={{ border: "1px solid #E0E0E0", padding: "0 8px 0 8px" }}>email</td>
            <td style={{ border: "1px solid #E0E0E0", padding: "0 8px 0 8px" }}>phone</td>
          </tr>
          <tr>
            <td style={{ border: "1px solid #E0E0E0", padding: "0 8px 0 8px" }}>test@gmail.com</td>
            <td style={{ border: "1px solid #E0E0E0", padding: "0 8px 0 8px" }}>1231231234</td>
          </tr>
          <tr>
            <td style={{ border: "1px solid #E0E0E0", padding: "0 8px 0 8px" }}></td>
            <td style={{ border: "1px solid #E0E0E0", padding: "0 8px 0 8px" }}>1231231234</td>
          </tr>
          <tr>
            <td style={{ border: "1px solid #E0E0E0", padding: "0 8px 0 8px" }}>test2@gmail.com</td>
            <td style={{ border: "1px solid #E0E0E0", padding: "0 8px 0 8px" }}></td>
          </tr>
        </table> */}

        <Box className={style.create_source_item}>
          {file ? (
            <Box className={style.uploadedFileWrapper}>
              <Typography variant={"body1"} className={style.fileTitle}>
                {file?.path}
              </Typography>
              <IconButton aria-label="delete" onClick={handleDeleteFile}>
                <img src={DeleteIcon} alt="" />
              </IconButton>
            </Box>
          ) : (
            <Dropzone onDrop={handleSetFile} />
          )}
        </Box>
        <Box sx={{ display: "flex", gap: "20px", margin: "20px 0 20px 0" }}>
          <CancelButton handleCloseSaveModal={handleCloseSaveModal} />
          <SaveButton text={"Import"} handlePost={handlePostListFile} validation={validation} />
        </Box>
      </Box>
      <Snackbar open={alert.isShow} autoHideDuration={3500} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity={alert.type} sx={{ width: "100%" }}>
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ImportFileModal;
