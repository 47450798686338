import moment from 'moment-timezone';

const getDateWeekArray = function () {
    const arr = [];
    const dt = new Date();
    for (let i = 0; i < 8; i++) {
        arr.push(dt.toLocaleDateString("en-us", {day: 'numeric', month: 'numeric'}));
        dt.setDate(dt.getDate() - 1);
    }
    return arr.reverse();
};

const getDateMonthArray = function () {
    const arr = [];
    const dt = new Date();
    for (let i = 0; i < 31; i++) {
        arr.push(dt.toLocaleDateString("en-us", {day: 'numeric', month: 'short'}));
        dt.setDate(dt.getDate() - 1);
    }
    return arr.reverse();
};

const getHourLabels = () => {
    const hourLabels = [];
    for (let i = 0; i < 24; i++) {
        const hour = i % 12 === 0 ? 12 : i % 12;
        const period = i < 12 ? 'am' : 'pm';
        hourLabels.push(`${hour}${period}`);
    }
    return hourLabels;
};


export const monthLabels = getDateMonthArray();

export const daysLabels = getDateWeekArray();

export const hourLabels = getHourLabels();


export const convertTimestamp = (timestamp) => moment.unix(timestamp).tz(process.env.REACT_APP_TIME_ZONE).format("MM/DD/YYYY hh:mm:ss A");
export const convertTimestampString = (timestamp) => moment(timestamp).tz(process.env.REACT_APP_TIME_ZONE).format("MM/DD/YYYY hh:mm:ss A");

export const timeZoneChanger = time => time.toLocaleString('en-US', {timeZone: process.env.REACT_APP_TIME_ZONE});

export const ModuleAttributes = {
    "firstName": "string",
    "lastName": "string",
    "email": "string",
    "phone": "string",
    "ssn": "string",
    "birthDate": "string",
    "driverLicenseState": "string",
    "driverLicenseNumber": "string",
    "ownCar": "string",
    "activeMilitary": "string",
    "ownHome": "string",
    "address": "string",
    "address2": "string",
    "addressNumber": "string",
    "addressLengthMonths": "string",
    "city": "string",
    "state": "string",
    "zip": "string",
    "homePhone": "string",
    "workPhone": "string",
    "bestTimeToCall": "string",
    "incomeType": "string",
    "employer": "string",
    "employedMonths": "string",
    "jobTitle": "string",
    "monthlyIncome": "string",
    "directDeposit": "string",
    "payFrequency": "string",
    "payDate1": "string",
    "payDate2": "string",
    "bankAccountType": "string",
    "bankAba": "string",
    "bankAccountNumber": "string",
    "bankAccountLengthMonths": "string",
    "bankName": "string",
    "bankPhone": "string",
    "ipAddress": "string",
    "domain": "string",
    "source": "string",
    "vertical": "string",
    "price": "string",
    "optInDate": "string",
    "creditScore": "string",
    "loanAmount": "string",
    "loanReason": "string",
    "loanPreference": "string",
    "zodiac": "string"
}
export const dataProcessingTypes = {
    BY_INCOME_DATA: 'by_income_data',
    BY_MATCHING_DB_DATA: 'by_matching_db_data',
    MERGE_BY_INCOME_DATA: 'merge_by_income_data',
    MERGE_BY_MATCHING_DB_DATA: 'merge_by_matching_db_dta'
}

export const excludeMasterRecordsHeader = [
    "id",
    "created_by",
    "ssn",
    "ipAddress",
    "ip_address",
    "hashed_email",
    "hashed_cell_phone",
    "promocode",
    "promocode_v2",
    "census_match",
    "master_record",
    "master_record_history",
    "emails",
    "phones"

  ];

  export const dateFormater = ["birth_date", "opt_in_date", "pay_date_1", "pay_date_2"];
  export const dateFormatedFull = ["created_at", "updated_at"];

  export const filterRecord = {
    NEW_RECORD: "new record",
    OLD_RECORD: "old record"

  }
