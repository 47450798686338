import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Box, TextField, Typography, Tooltip } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import { getStoragesCollections } from "../../services/DataService";
import ListTable from "../../components/ListTable/ListTabel";
import SearchButton from "../../components/SaveModalButtons/SearchButton";
import ReactJson from "react-json-view";
import style from "./styles.module.css";
import { convertTimestampString } from "../../utils/constants";

const StorageCollectionsPage = () => {
  const [data, setData] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchTerm, setSearchTerm] = useState("");

  const { id } = useParams();
  const response = useQuery(["getStoragesCollections", id, page, rowsPerPage], () => getStoragesCollections(id, page, rowsPerPage, searchTerm));

  useEffect(() => {
    if (response.isSuccess) {
      setData(response.data);
    }
    // eslint-disable-next-line
  }, [response.isSuccess]);

  const headers = [
    {
      lable: "Created at",
      align: "left",
      icon: true,
    },
    {
      lable: "Email",
      align: "left",
    },
    {
      lable: "Phone",
      align: "left",
    },
    {
      lable: "Data",
      align: "left",
    },
  ];
  const columns = [
    {
      accessor: (element) => convertTimestampString(element["createdAt"]),
      Cell: (event, storage) => {},
      align: "left",
    },
    {
      accessor: (element) => element["email"],
      Cell: (event, storage) => {},
      align: "left",
    },
    {
      accessor: (element) => (element["phone"] ? element["phone"] : element?.rawData?.cellPhone ? element?.rawData?.cellPhone : "-"),
      Cell: (event, storage) => {},
      align: "left",
    },
    {
      accessor: (element) => {
        return (
          <Box>
            <Tooltip
              title={
                <ReactJson
                  src={element["rawData"]}
                  name="Data"
                  enableClipboard={false}
                  iconStyle="circle"
                  theme="summerfruit:inverted"
                  displayObjectSize={false}
                  displayDataTypes={false}
                />
              }
              arrow
              placement="bottom-start"
            >
              <Box>{JSON.stringify(element["rawData"])}</Box>
            </Tooltip>
          </Box>
        );
      },
      Cell: (event, storage) => {},
      align: "left",
      style: {
        padding: "10px 16px 10px 16px",
      },
    },
  ];
  const handleSearch = async () => {
    setPage(0);
    const newData = await getStoragesCollections(id, 0, rowsPerPage, searchTerm);
    setData({ ...newData });
  };
  const handleChangeTerm = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };
  return (
    <div>
      <Box className="wrapper-source-list" sx={{ padding: "24px" }}>
        <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <Box className={style.InputStyle}>
            <Typography variant={"subtitle2"}>Term</Typography>
            <TextField onChange={handleChangeTerm} value={searchTerm} placeholder="Term" />
          </Box>
          <Box sx={{ padding: "16px 0 0 0" }}>
            <SearchButton handleSearch={handleSearch} />
          </Box>
        </Box>
        <ListTable headers={headers} data={data?.data} columns={columns} />
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={data?.total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </div>
  );
};

export default StorageCollectionsPage;
