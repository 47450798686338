import { useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAppState } from "../contexts/AppContext";
import { useLogout } from "./useLogout";
import { editListItems, getListItems, postListItems, postListItemsImport } from "../services/DataService";


export function useListItemsAPI({ listId, handleCloseSaveModal }) {
  const { handleOpenAlert } = useAppState();
  const { logout } = useLogout();
  const queryClient = useQueryClient();
  const listItemsData = useQuery(["getListItems", listId], () => getListItems(listId));

  const postListItem = useMutation(postListItems, {
    onSuccess: (data) => {
      handleOpenAlert("success", "List item created successfully");
      setTimeout(() => {
        handleCloseSaveModal();
      }, [1000]);
    },
    onError: (err) => {
      if (err.response.status === 401) {
        logout();
      }
      handleOpenAlert("error", err.response.data.errors[0].msg);
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });

	const postListItemImport = useMutation(postListItemsImport, {
    onSuccess: (data) => {
      handleOpenAlert("success", "List file imported successfully");
      setTimeout(() => {
        handleCloseSaveModal();
      }, [1000]);
    },
    onError: (err) => {
      if (err.response.status === 401) {
        logout();
      }
      handleOpenAlert("error", err.response.data.errors[0].msg);
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });

  const editListItem = useMutation(editListItems, {
    onSuccess: (data) => {
      handleOpenAlert("success", "List item created successfully");
      setTimeout(() => {
        handleCloseSaveModal();
      }, [1000]);
    },
    onError: (err) => {
      if (err.response.status === 401) {
        logout();
      }
      handleOpenAlert("error", err.response.data.errors[0].msg);
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });

  return useMemo(
    () => ({
      postListItem,
			postListItemImport,
      editListItem,
      listItemsData,
    }),
    [postListItem, postListItemImport, editListItem, listItemsData]
  );
}
